import { useEffect, useState } from "react";
import Logo from "../../shared/Logo";

const Header = () => {
	// const title: string = 'cellule logiciels et informatique collaborative';
	// const colorList = useMemo(function (): string[] { return ['green', 'orange', 'pink', 'blue']}, []);

	// const [colors, setColors] = useState<string[]>(colorList);

    const [scrollPosition, setScrollPosition] = useState(0);
	const handleScroll = () => {
		setScrollPosition(window.scrollY);
		// setColors((prev) => [...prev].sort(() => (0.5 - Math.random())));
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const smoothScrollToAnchor = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, target: string) => {
		let targetElement = document.getElementById(target);
		event.preventDefault();
		targetElement && targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
	}

    return (
		<div className={`header ${scrollPosition > 5 ? 'shadow' : ''}`}>
			<div className="header__logo">
				<Logo img='clic-logo.png' alt='Logo CLIC' class='clic-logo' />
			</div>
			<ul className="header__title">
				<li><a href="/" onClick={e => smoothScrollToAnchor(e, 'presentation')}>Présentation</a></li>
				<li><a href="/" onClick={e => smoothScrollToAnchor(e, 'tech')}>Stack</a></li>
				<li><a href="/" onClick={e => smoothScrollToAnchor(e, 'stat')}>Clients</a></li>
				<li><a href="/" onClick={e => smoothScrollToAnchor(e, 'projects')}>Projets</a></li>
				<li><a href="/" onClick={e => smoothScrollToAnchor(e, 'team')}>L'équipe</a></li>
				<li><a href="/" onClick={e => smoothScrollToAnchor(e, 'contact')}>Contact</a></li>
				{/* <h1>{ title.split(' ').map(text => <ColoredWord key={text} text={text} color={colors[Math.floor(Math.random() * colors.length)]} />) }</h1> */}
			</ul>
			<div className="header__logo">
				<Logo img='lgm.png' alt='Logo LGM' />
			</div>
		</div>
    );
}

// const ColoredWord = (props: {text: string, color: string}) => {
// 	return <span>
// 		<strong 
// 			className={props.text.length > 2 
// 				? `firstLetter ${props.color}` 
// 				: ''}
// 			>{props.text[0]}
// 		</strong>
// 		{props.text.slice(1)}&emsp;
// 	</span>;
// }

export default Header;