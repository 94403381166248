import { profileModel } from "../classes/profile.model";

export const PROFILES: profileModel[] = [
	{firstname: 'Antoine', lastname: 'Levy', picture: 'aly'},
	{firstname: 'Alexandre', lastname: 'Simonin', picture: 'asn'},
	{firstname: 'Bruno', lastname: 'Nathan', picture: 'bnn'},
	{firstname: 'Christopher', lastname: 'Geroult Croain', picture: 'cgn'},
	{firstname: 'Guillaume', lastname: 'Swiatek', picture: 'gsk'},
	{firstname: 'Jonathan', lastname: 'Perennes', picture: 'jps'},
	{firstname: 'Mohammed', lastname: 'Bareche', picture: 'mbe'},
	{firstname: 'Marie-Anne', lastname: 'Clay', picture: 'mcy'},
	{firstname: 'Malek', lastname: 'Nouar', picture: 'mnr'},
	{firstname: 'Nicolas', lastname: 'Morisseau', picture: 'nmu'},
	{firstname: 'Rémy', lastname: 'Quidal', picture: 'rql'},
	{firstname: 'Sylvain', lastname: 'Delebreu', picture: 'sdu'},
	{firstname: 'Simon', lastname: 'Romain', picture: 'srn'},
	{firstname: 'Stéphanie', lastname: 'Tran<br />Van Thoan', picture: 'stn'},
	{firstname: 'Thierry', lastname: 'Gaiola', picture: 'tga'},
];