import { GoogleMap, InfoWindowF, MarkerF } from "@react-google-maps/api";
import Logo from "../../shared/Logo";

const CENTER = { lat: 48.78317475614868, lng: 2.211530805178005 };
const ZOOM = 15;

const LgmMap = () => {
    return (
        <section className='half-page' id='lgm-map'>
            <GoogleMap 
                zoom={ZOOM} 
                center={CENTER} 
                mapContainerClassName='map-container'
            >
                <MarkerF position={CENTER}>
                    <InfoWindowF position={CENTER}>
                        <div className="map-info">
                            <Logo img='lgm.png' alt='Logo LGM' />
                        </div>
                    </InfoWindowF>
                </MarkerF>
            </GoogleMap>
        </section>
    );
}

export default LgmMap;