import Title from "../../shared/Title";

const Project = () => {
    return (
        <section className='page' id="projects">
            <div className="project">
                <Title text="Nos projets"/>
                <div className="project__bloc">
                    <iframe scrolling="no" id="projects__iframe" title="projects" src="https://clichub-legacy.thierryg.com/projects.html"></iframe>
                    {/* <Swiper 
                        centeredSlides={false}
                        effect={'cards'}
                        grabCursor={true}
                        loop={true}
                        modules={[Autoplay]}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false
                        }}
                        breakpoints={{
                            1: { slidesPerView: 1 },
                            768: { slidesPerView: 2 },
                            1200: { slidesPerView: 3 },
                            1700: { slidesPerView: 4 }
                        }}>
                        { 
                            PROJECTS.map(p => 
                                <SwiperSlide key={p.projects[0]} className="client__bloc"><ProjectBloc key={p.picture} project={p} /></SwiperSlide>
                            )
                        }
                    </Swiper> */}
                    {/* { PROJECTS.map((p: ProjectModel) => <ProjectBloc key={p.picture} project={p} />) } */}
                </div>
            </div>
        </section>
        
    );
}

// const ProjectBloc = (props: {project: ProjectModel}) => {
//     return (
//         <div className="project__bloc__item">
//             <div className="project__bloc__item-logo"><ProjectLogo project={props.project} /></div>
//             <div className="project__bloc__item-title"><p>{props.project.client}</p></div>
//             <div className="project__bloc__item-text">
//                 <p>
//                     { props.project.projects.map((p: string) => <span key={p}>{p}<br /></span>) }
//                 </p>
//             </div>
//         </div>
//     );
// }

export default Project;