import { techModel } from "../classes/tech.model";

export const TECHS: techModel[] = [
	{name: 'C#', picture: 'csharp'},
	{name: 'Dotnet', picture: 'dotnetcore'},
	{name: 'Angular', picture: 'angular'},
	{name: 'Vue JS', picture: 'vuejs'},
	{name: 'Javascript', picture: 'js'},
	{name: 'Typescript', picture: 'ts'},
	{name: 'Ionic', picture: 'ionic'},
	{name: 'SignalR', picture: 'signalr'},
	{name: 'Redis', picture: 'redis'},
	{name: 'PostgreSQL', picture: 'postgres'},
	{name: 'SQL Server', picture: 'sqlserver'},
	{name: 'Mongo DB', picture: 'mongodb'}
];