import Contact from './components/Contact';
import Header from './components/Header';
import Team from './components/Team';
import Client from './components/Client';
import LgmMap from './components/LgmMap';
import Presentation from './components/Presentation';
import Tech from './components/Tech';
import Project from './components/Project';
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import React, { MutableRefObject, useEffect, useRef } from 'react';
import Stat from './components/Stat';
import '../assets/styles/app.scss';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

ScrollTrigger.defaults({
	toggleActions: "restart pause resume pause",
	scroller: ".container"
});

function App() {

	const presentationRef = useRef() as MutableRefObject<HTMLDivElement>;
	const techRef = useRef() as MutableRefObject<HTMLElement>;
	const clientRef = useRef() as MutableRefObject<HTMLElement>;
	const projectRef = useRef() as MutableRefObject<HTMLElement>;
	const contactRef = useRef() as MutableRefObject<HTMLElement>;
	const teamRef = useRef() as MutableRefObject<HTMLElement>;
	  
	useEffect(() => {
		let ctx = gsap.context(() => {

			gsap.timeline({ scrollTrigger: { trigger: presentationRef.current } })
			.from(".pres__title", { opacity: 0, yPercent: -100, ease: "elastic", duration: 2 }, 1)
			.from(".presentation__title h1", { opacity: 0, yPercent: 400, ease: "elastic", duration: 2 }, 2)
			.from(".presentation-content__graph", { opacity: 0, xPercent: -100 }, 3)
			.from(".presentation-content__text", { opacity: 0, xPercent: 100 }, 3)

			gsap.timeline({ scrollTrigger: { trigger: techRef.current } })
			.from(".tech__title", { opacity: 0, xPercent: -100, ease: "power2" }, 1)
			.from(".tech__logo", { opacity: 0, xPercent: 100 }, 1);

			gsap.timeline({ scrollTrigger: { trigger: clientRef.current } })
			.from(".stat__title", { opacity: 0, yPercent: -100, ease: "back" }, 1)
			.from(".stat__body", { opacity: 0, xPercent: 100 }, 2)
			.from(".client__title", { opacity: 0, yPercent: 100, ease: "back" }, 3)
			.from(".client__body", { opacity: 0, yPercent: 200 }, 4);

			gsap.timeline({ scrollTrigger: { trigger: contactRef.current } })
			.from(".contact__title", { opacity: 0, yPercent: -100 }, 1)
			.from(".contact__location", { opacity: 0, xPercent: -100 }, 2)
			.from(".contact__mail", { opacity: 0, xPercent: 100 }, 2)
			.from("#lgm-map", { opacity: 0, yPercent: 100 }, 3);
		});

		return () => ctx.revert();
	});


	return (
		<React.Fragment>
			<div className="container">
				<Header />
				<section className="panel" ref={presentationRef}>
					<Presentation />
				</section>

				<section className="panel orange" ref={techRef}>
					<Tech />
				</section>

				<section className="panel" ref={clientRef}>
					<Stat />
					<Client />
				</section>

				<section className="panel" ref={projectRef}>
					<Project />
				</section>
				<section className="panel" ref={teamRef}>
					<Team />
				</section>
				<section className="panel" ref={contactRef}>
					<Contact />
					<LgmMap />
				</section>
			</div>
		</React.Fragment>
	);
}

export default App;
