import { profileModel } from "../app/classes/profile.model";
import { importAll } from "./importAll";

const Profile = (props: {profile: profileModel}) => {
	const images: {[key: string]: any} = importAll(require.context('../assets/images/profiles/', false, /\.(PNG|jpe?g|svg)$/));
	return (
		<div>
			<img className="team__profile-picture" src={images[`${props.profile.picture}.PNG`]} alt={props.profile.firstname} />
			<p dangerouslySetInnerHTML={{ __html: props.profile.firstname}}></p>
		</div>
	);
}

export default Profile;