import { faHeadset, faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import MailTo from "../../shared/MailTo";
import Title from "../../shared/Title";

const Contact = () => {
    const [beat, setBeat] = useState(false);
    
    return (
        <section className='half-page' id="contact">
            <div className='contact__title orange'><Title text="Nous contacter" /></div>
            <div className='contact' onMouseEnter={() => setBeat(true)} onMouseLeave={() => setBeat(false)}>
                <div className='contact__location'>
                    <FontAwesomeIcon icon={faMapLocationDot} size='3x' className='blue' beatFade={beat} />
                    <h3>Adresse</h3>
                    <p>13 Avenue Morane Saulnier<br />78140 Vélizy-Villacoublay, France</p>
                </div>
                <div className='contact__mail'>
                    <FontAwesomeIcon icon={faHeadset} size='3x' color='green' beatFade={beat}/>
                    <h3>Nous contacter</h3> 
                    <p><MailTo mail='thierry.gaiola@lgm.fr' subject='Contact depuis le CLIC Hub' /><br />+33 (0) 1 30 67 08 00</p>
                </div>
            </div>
        </section>
    );
}

export default Contact;