import { techModel } from "../app/classes/tech.model";
import { importAll } from "./importAll";

const TechLogo = (props: {tech: techModel}) => {
	const images: {[key: string]: any} = importAll(require.context('../assets/images/tech/', false, /\.(png|jpe?g|svg)$/));
	return (
		<div className="tech__logo__bloc">
			<img className="tech__logo__img" src={images[`${props.tech.picture}.png`]} alt={props.tech.name} />
			<p>{props.tech.name}</p>
		</div>
	);
}

export default TechLogo;