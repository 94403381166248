import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { importAll } from "../../shared/importAll";
import 'swiper/css';
import "swiper/css/pagination";
import { CLIENTS } from '../constants/clients';
import Title from '../../shared/Title';

const Client = () => {

	const images: {[key: string]: any} = importAll(require.context('../../assets/images/clients/', false, /\.(png|jpe?g|svg)$/));

	return (
        <section className='half-page' id='clients'>
            <div className='client'>
                <div className='client__title'><Title text="Happy Clients" /></div>
                <div className='client__body'>
                    <Swiper 
                        centeredSlides={false}
                        effect={'cards'}
                        grabCursor={true}
                        loop={true}
                        modules={[Autoplay]}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false
                        }}
                        breakpoints={{
                            1: { slidesPerView: 1 },
                            768: { slidesPerView: 2 },
                            1200: { slidesPerView: 3 },
                            1700: { slidesPerView: 4 }
                        }}>
                        { 
                            CLIENTS.map(client => 
                                <SwiperSlide key={client} className="client__bloc"><img src={images[`${client}.png`]} alt='' /></SwiperSlide>
                            )
                        }
                    </Swiper>
                </div>
            </div>
        </section>
	);
}

export default Client;