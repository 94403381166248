import { importAll } from "./importAll";

const Logo = (props : {img: string, alt: string, class?: string}) => {
	const images: {[key: string]: any} = importAll(require.context('../assets/images/', false, /\.(png|jpe?g|svg)$/));
	return (
        <img className={props.class ? props.class : ''} src={images[props.img]} alt={props.alt} />
	);
}
  
export default Logo;
  