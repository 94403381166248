import TechLogo from "../../shared/TechLogo";
import Title from "../../shared/Title";
import { TECHS } from "../constants/techs";

const Tech = () => {  
    return (
        <section className='page' id="tech">
            <div className="tech">
                <div className="tech__title"><Title text="Nos technologies" /></div>
                <div className='tech__logo'>
                    { TECHS.map(t => <TechLogo key={t.picture} tech={t} />) }
                </div>
            </div>
        </section>
    );
}

export default Tech;