import Carousel from "react-carousel3/dist/esm/Carousel";
import Profile from "../../shared/Profile";
import Title from "../../shared/Title";
import { PROFILES } from '../constants/profiles';

const Team = () => {
    return (
        <section className='page' id="team">
            <div className='team'>
                <Title text="L'équipe" />
                {/* <div className='team__profiles'>
                    { PROFILES.map(p => <Profile key={p.picture} profile={p} />) }
                </div> */}
                <div className="team__bloc">
                    <Carousel 
                        height={600} 
                        width={1200} 
                        xOrigin={20} 
                        yOrigin={-255} 
                        xRadius={600}
                        yRadius={600}
                        autoPlay={true}>
                        { PROFILES.map(p => 
                            <div key={p.picture}>
                                <Profile key={p.picture} profile={p} />
                            </div>
                        )}
                    </Carousel>
                </div>
            </div>
        </section>
    );
}

export default Team;