export const CLIENTS: string[] = [
    'alstom',
    'cea',
    'cosea',
    'driea',
    'eurotunnel',
    'lisea',
    'lmcu',
    'mbda',
    'ratp',
    'sgp',
    'sncf',
    'snecma'
];