import Title from "../../shared/Title";

const Presentation = () => {
	return (
		<section className='page' id="presentation">
			<div className='presentation'>
				<div className="presentation__title">					
					<div className="pres__title"><Title text="Qui sommes nous ?" /></div>			
					<h1>
						<span className="firstLetter blue">C</span>ellule&nbsp;
						<span className="firstLetter pink">L</span>ogiciels&nbsp;et&nbsp;
						<span className="firstLetter green">I</span>nformatique&nbsp;
						<span className="firstLetter orange">C</span>ollaborative
					</h1>		
				</div>
				<div className="presentation-content">
					<div className="presentation-content__graph">
						<iframe scrolling="no" id="graph" title="graph" src="https://clichub-legacy.thierryg.com/graph.html"></iframe>
					</div>
					<div className="presentation-content__text">
						<p>
							La <b>CLIC</b> dispose d'une force de frappe dédiée au développement d'outils ultra spécifiques.
						</p>
						<p>
							Nous travaillons de façon intégrée dans les projets des équipes <b>SPRING</b> et <b>PERFO</b> pour les doter d'outils de pilotage et de reporting, de simulation dymanique de flux, des outils experts et des outils opérationnels.
						</p>
						<p>
							Nous privilégions toujours <b>l'innovation</b> et les technologies les plus récentes car nous estimons que nos clients méritent le meilleur et parce que nos équipes adorent cela.
						</p>
						<p>
							Nos connaissances du métier et du secteur sont des conditions essentielles pour que nos compétences en développement informatique aient vraiment du sens, et ainsi atteindre <b>l'excellence</b>.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Presentation;