import { faClock, faPerson, faSuitcase, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Title from '../../shared/Title';

const Stat = () => {  
    return (
        <section className='half-page' id="stat">
            <div className="stat">
                <div className="stat__title"><Title text="En chiffres" /></div>
                <div className='stat__body'>
                    <div>
                        <FontAwesomeIcon icon={faTrophy} size='4x' className='blue' />
                        <h1 className='stat__number'>6</h1>
                        <h3>DTI</h3>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faClock} size='4x' className='orange' />
                        <h1 className='stat__number'>{Math.ceil((new Date().getTime() - new Date(2015, 10-1, 1).getTime()) / (1000 * 3600 * 24))}</h1>
                        <h3>JOURS DEPUIS NAISSANCE</h3>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faSuitcase} size='4x' className='green' />
                        <h1 className='stat__number'>104</h1>
                        <h3>PROJETS</h3>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faPerson} size='4x' className='pink' />
                        <h1 className='stat__number'>13</h1>
                        <h3>MEMBRES</h3>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Stat;

